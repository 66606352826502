





























import Feature from '../mixins/Feature'
import mixins from 'vue-typed-mixins'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
export default mixins(Feature).extend({
  name: 'Feature8',
  components: { DefaultButton }
})
